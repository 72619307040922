<pk-broker-loader [show]="loading"></pk-broker-loader>
<section class="container-fluid support-center-container" *ngIf="!loading">
	<div>Ticket #{{ id }}</div>
	<div class="row">
		<div class="col-12 col-md-7 order-1 order-md-0 pt-3">
			<div class="row">
				<div class="col">
					<h3 class="mb-0 pb-0 no-underline"><fa-icon [icon]="['fas', 'comments']"></fa-icon> Updates</h3>
				</div>
				<div class="col-auto">
					<span class="font-size-085">Sorted by </span>
					<a href="javascript:void(0)" (click)="reorderComments()" id="commentOrder">
						<span class="blue-text semibold-text font-size-085 mr-3">
							{{ commentOrder === 'newest' ? 'Newest' : 'Oldest' }}
							<fa-icon
								[icon]="['fas', commentOrder === 'newest' ? 'caret-down' : 'caret-up']"
								style="position: absolute; padding-left: 0.5rem; font-size: 1rem; top: 0; color: #1a2574"
								aria-hidden="true"></fa-icon>
						</span>
					</a>
				</div>
			</div>
			<div class="row" *ngIf="canAddComment">
				<div class="col">
					<hr class="divider1px my-1" />
					<p class="mb-4">
						<a href="javascript:void(0)" (click)="toggleAddComment()" class="semibold-text">
							<fa-icon [icon]="['fas', isAddingComment ? 'xmark' : 'reply']"></fa-icon>Comment
						</a>
					</p>
				</div>
			</div>
			<div *ngIf="isAddingComment">
				<form [formGroup]="formGroup">
					<div class="row">
						<div class="col form-element-container" [class.invalid]="err.body">
							<label for="comment" class="required">Comment</label>
							<pk-broker-loader [show]="loadingCommentEditor"></pk-broker-loader>
							<pk-broker-html-editor
								id="comment"
								formControlName="body"
								[invalid]="err.body"
								(loaded)="onLoadedCommentEditor($event)"
								[showTemplates]="true"
								[aiGeneratedResponseId]="ticket.latestAiGeneratedContent?.id"></pk-broker-html-editor>
							<p class="mb-0 invalid-feedback" *ngIf="err.body">
								<ng-container *ngIf="err.body.required">Comment is required</ng-container>
							</p>
						</div>
					</div>
					<div class="row my-2" *ngIf="loggedInUser.isAdmin">
						<div class="col-auto form-element-container">
							<div class="custom-control custom-radio">
								<input
									type="radio"
									[value]="false"
									id="isInternal_0"
									formControlName="isInternal"
									class="custom-control-input"
									[attr.disabled]="ticket.isCompleted ? true : null" />
								<label class="custom-control-label" for="isInternal_0">Public</label>
							</div>
						</div>
						<div class="col-auto form-element-container">
							<div class="custom-control custom-radio">
								<input
									type="radio"
									[value]="true"
									id="isInternal_1"
									formControlName="isInternal"
									class="custom-control-input"
									[attr.disabled]="ticket.isCompleted ? true : null" />
								<label class="custom-control-label" for="isInternal_1">Private</label>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="loggedInUser.isAdmin && ticket?.latestAiGeneratedContent?.attachmentId">
						<div class="col-auto form-element-container">
							<div class="custom-control custom-checkbox">
								<input class="custom-control-input" type="checkbox" id="includeOCAttachment" formControlName="includeOCAttachment" />
								<label class="custom-control-label" for="includeOCAttachment">Include OC Attachment</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col pb-0 form-element-container">
							<pk-broker-file-selector
								#fileSelector
								label="Attachment(s)"
								formControlName="attachments"
								[multiple]="true"
								[softDelete]="false"
								accept=".doc,.docx,.pdf,.txt,.bmp,.gif,.png,.jpg,.jpeg,.tiff,.xlsx,.xls,.xlsm,.xlsb,.csv,.zip"
								title="Tickets"
								helperText="You may select multiple files from the dialog."
								[applyImmediately]="true">
							</pk-broker-file-selector>
						</div>
					</div>

					<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>

					<div class="row">
						<div class="col-12 text-sm-right">
							<button type="button" class="btn btn-link" (click)="toggleAddComment()">Cancel</button>
							<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<hr class="divider mt-4 mb-5" />
						</div>
					</div>
				</form>
			</div>
			<div>
				<div *ngFor="let comment of comments">
					<pk-broker-comment-block
						[comment]="comment"
						[ticket]="ticket"
						[ticketCategories]="ticketCategories"
						[users]="users"
						[contactMethods]="contactMethods"
						(submitted)="onCommentSubmitted()">
					</pk-broker-comment-block>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-5 order-0 order-md-1 mb-3 mb-md-0 rounded-corner-div off-row-gray-bg">
			<div class="row">
				<div class="col-auto mb-2 pr-1 font-size-125 blue-text">
					<fa-icon [icon]="['fas', 'caret-square-right']"></fa-icon>
				</div>
				<div class="col mb-2 pl-1">
					<h3 class="mb-1 add-underline">{{ ticket.subject }}</h3>
					<p class="semibold-text blue-text">Created {{ ticket.addDate | date: 'MM/dd/yyyy' }}</p>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<p class="mb-2">Category</p>
				</div>
				<div class="col-8">
					<p class="mb-2">
						<span class="semibold-text">{{ ticket.ticketCategoryName }}</span>
					</p>
				</div>
			</div>
			<div class="row" *ngIf="loggedInUser.isAdmin">
				<div class="col-4">
					<p class="mb-2">Priority</p>
				</div>
				<div class="col-8" *ngIf="loggedInUser.isAdmin">
					<pk-broker-filter-dropdown [options]="priorityList" [initialOption]="ticket.priorityDisplay || 'Low'" (selection)="changePriority($event)">
					</pk-broker-filter-dropdown>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<p class="mb-2">Status</p>
				</div>
				<div class="col-8" *ngIf="loggedInUser.isAdmin">
					<p class="mb-2 blue-text semibold-text font-size-085">
						<pk-broker-filter-dropdown [options]="statusFilterDropdownList" [initialOption]="ticketStatus" (selection)="changeStatus($event)">
						</pk-broker-filter-dropdown>
					</p>
				</div>
				<div class="col-8" *ngIf="!loggedInUser.isAdmin">
					<p class="mb-2 blue-text semibold-text font-size-085">
						{{ ticketStatus }}
					</p>
				</div>
			</div>
			<div class="row" *ngIf="loggedInUser.isAdmin">
				<div class="col-4">
					<p class="mb-2">Assignee</p>
				</div>
				<div class="col-8" *ngIf="assignedToUserList">
					<pk-broker-filter-dropdown
						[options]="assignedToUserList"
						[initialOption]="ticket.assigneeFullName"
						nullOption="Unassigned"
						(selection)="changeAssignedUser($event)">
					</pk-broker-filter-dropdown>
				</div>
			</div>
			<div class="row" *ngIf="loggedInUser.isAdmin">
				<div class="col-4">
					<p class="mb-2">Reporter</p>
				</div>
				<div class="col-8">
					<p class="mb-2">
						<a *ngIf="!ticket.agent" [attr.href]="'mailto:' + ticket.email + '?Subject=' + ticket.subject" class="semibold-text" tooltip="Email">
							{{ ticket.email }}
						</a>
						<a
							*ngIf="ticket.agent"
							[routerLink]="['/agent', ticket.agent.agentId, 'info']"
							target="_blank"
							class="semibold-text"
							tooltip="Agent Info">
							{{ ticket.reporterFullName }} ({{ ticket.agent.name }})
						</a>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<p class="mb-2">Residential</p>
				</div>
				<div class="col-8">
					<p class="mb-2">
						<span class="semibold-text">{{ isResidential }}</span>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<p class="mb-2">Preferred Contact</p>
				</div>
				<div class="col-8">
					<p class="mb-2">
						<span class="semibold-text">{{ ticketContactMethods }}</span>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<p class="mb-2">Contract(s)</p>
				</div>
				<div class="col-8">
					<p class="mb-2" *ngFor="let contract of ticket.contracts">
						<a [routerLink]="['/contract', contract.contractId, 'info']" target="_blank" class="semibold-text">{{ contract.displayContractNum }}</a>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<p class="mb-2">Completion Date</p>
				</div>
				<div class="col-8">
					<p class="mb-2">
						<span class="semibold-text">{{ ticket.completeDate ? (ticket.completeDate | date: 'MM/dd/yyyy') : 'TBD' }}</span>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<p class="mb-2">Completed by</p>
				</div>
				<div class="col-8">
					<p class="mb-2">
						<span class="semibold-text">{{ ticket.getCompletedFullName(loggedInUser) }}</span>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<hr class="divider1px" />
					<p *ngIf="loggedInUser.isAdmin || ticketStatus === 'Submitted'">
						<a href="javascript:void(0)" (click)="openTicketEditModal(ticket)" class="semibold-text">
							<fa-icon [icon]="['fas', 'pencil']"></fa-icon>Edit Ticket
						</a>
					</p>
					<p>
						<a
							href="javascript:void(0)"
							class="semibold-text"
							[ngClass]="{ disabled: isCompleteButtonDisabled }"
							(click)="setToComplete()"
							*ngIf="ticket.statusId !== CONSTANTS.ticketStatuses.complete">
							<fa-icon [icon]="['fas', 'edit']"></fa-icon>
							{{ isCompleteButtonText }}
						</a>
					</p>
					<p>
						<a
							href="javascript:void(0)"
							class="semibold-text"
							[ngClass]="{ disabled: isDeleteButtonDisabled }"
							(click)="openDeleteTicketModal()"
							*ngIf="ticket.statusId !== CONSTANTS.ticketStatuses.complete">
							<fa-icon [icon]="['fas', 'trash']"></fa-icon>
							{{ deleteButtonText }}
						</a>
					</p>
					<hr class="divider1px" />
					<h4 class="blue-text">Attachments</h4>
					<p class="text-nowrap" *ngFor="let attachment of ticket.attachments">
						<a
							[attr.href]="environment.baseUrl + '/attachments/' + attachment.attachmentId + '/view?access_token=' + environment.apiKey"
							target="_blank">
							<fa-icon [icon]="['fas', 'file']"></fa-icon>{{ attachment.originalName || attachment.name }}
						</a>
					</p>
					<tr *ngIf="!ticket.attachments?.length">
						<td colspan="4"><em>No Attachments Found</em></td>
					</tr>
				</div>
			</div>
		</div>
	</div>
</section>
