import { SmartEnrollOption } from './smart-enroll-option';

export class ContractSmartEnrollSetting {

	public smartEnrollOptionId: number;
	public smartEnrollErrorMessage: string;
	public maxTerm: number;

	public smartEnrollOption: SmartEnrollOption;
}
