export class PricingCopilotMonthlyAuctionPerformance {
	participationRate: number;
	participationRateDiff: number;
	winRate: number;
	winRateDiff: number;
	deltaFromTop: number;
	deltaFromTopDiff: number;

	constructor(pricingCopilotMonthlyAuctionPerformance: PricingCopilotMonthlyAuctionPerformance) {
		Object.assign(this, pricingCopilotMonthlyAuctionPerformance);
	}
}
