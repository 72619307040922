import { Attachment } from './attachment';
import { Document } from './document';
import { ESign } from './esign';

export class EsignDocument {

	public esignDocumentId: string;
	public esignId: string;
	public attachmentId: string;
	public documentId: string;

	public accepted: boolean;
	public base64: string;

	public attachment: Attachment;
	public document: Document;
	public esign: ESign;

	// internal
	public downloading = false;

	constructor(esignDocument?: EsignDocument) {
		Object.assign(this, esignDocument);

		this.esign = this.esign ? new ESign(this.esign) : this.esign;
		this.document = this.document ? new Document(this.document) : this.document;
		this.attachment = this.attachment ? new Attachment(this.attachment) : this.attachment;
	}
}
