import { Utility } from './utility';

export class PricingAnalyticsSupplierSaleDataFilterOptions {
	public utilities: Utility[];
	public terms: number[];
	public usages: {
		text: string;
		minUsage: number;
		maxUsage: number;
	}[];

	constructor(pricingAnalyticsSupplierSalesDataFilterOptions?: PricingAnalyticsSupplierSaleDataFilterOptions) {
		Object.assign(this, pricingAnalyticsSupplierSalesDataFilterOptions);
	}
}
