import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { AttachmentService } from 'src/app/attachment/attachment.service';
import { GraphqlService } from 'src/app/graphql/graphql.service';
import { HelperService } from 'src/app/shared/helper.service';
import { Attachment } from 'src/app/shared/models';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'pk-broker-file-selector',
	templateUrl: './file-selector.component.html',
	styleUrls: ['./file-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			useExisting: FileSelectorComponent,
		},
	],
})
export class FileSelectorComponent implements ControlValueAccessor {

	public environment = environment;

	private files: File[];
	public attachments: Attachment[];
	private deleteQueue: Attachment[] = [];
	public disabled = false;
	private touched = false;
	public loading = false;
	public textInputId: string;
	public fileInputId: string;

	@Input()
	label: string = null;
	@Input()
	isRequired = false;
	@Input()
	set isDisabled(disabled: boolean) { this.setDisabledState(disabled); }
	@Input()
	maxMBSize = 50;
	@Input()
	accept: string = null;
	@Input()
	multiple = false;
	@Input()
	formData: { [name: string]: string | Blob };
	@Input()
	preview = false;
	@Input()
	softDelete = true;
	@Input()
	title = '';
	@Input()
	helperText = '';
	/**
	 * If database Attachments should be modified immediately on file changes. If false, the form control
	 * continues to update its value, but attachments will not be added/removed from the database
	 * until `applyChanges()` is called.
	 */
	@Input()
	applyImmediately = true;

	constructor(
		private toastrService: ToastrService,
		private attachmentService: AttachmentService,
		private graphqlService: GraphqlService,
	) {
		this.textInputId = HelperService.getUniqueId();
		this.fileInputId = HelperService.getUniqueId();
	}

	onChange = (_attachment: Attachment | Attachment[]) => { /* placeholder for interface */ };
	onTouched = () => { /* placeholder for interface */ };

	async onFileChange(event: EventTarget) {
		if (this.disabled || this.loading) { return; }

		this.markAsTouched();
		const target = (event as any).target as HTMLInputElement;
		this.files = this.multiple ? Array.from(target.files) : [target.files[0]];

		if (!HelperService.isFileSize(this.files, this.maxMBSize)) {
			this.toastrService.warning(`${this.files.length > 1 ? 'Each f' : 'F'}ile must be no more than ${this.maxMBSize} MB.`);
			target.value = '';
			this.files = [];
			return;
		}

		if (this.applyImmediately) {
			this.applyChanges();
		} else {
			this.attachments = this.files.map(f => new Attachment({ originalName: f.name, size: f.size }));
			this.onChange(!this.attachments.length ? null : this.multiple ? this.attachments : this.attachments[0]);
		}
	}

	/**
	 * Persists the new list of attachments to the database and updates the form control with new Attachment objects
	 * that include attachmentId.
	 *
	 * Only call this if `applyImmediately` is `false`. `data` is optional. If not provided, `formData` will be used.
	 */
	public async applyChanges(data: { [name: string]: string | Blob } = this.formData) {
		this.loading = true;

		await Promise.all(this.deleteQueue.map(attachment => this.deleteAttachment(attachment)));
		if (!this.files) {
			this.loading = false;
			return;
		}

		if (!this.multiple) {
			data.description ??= this.files[0].name;
		}
		const formData = new FormData();
		this.files.forEach(file => {
			formData.append('files', file, file.name);
		});
		// eslint-disable-next-line guard-for-in
		for (const name in data) {
			if (data[name] || data[name] === '') {
				formData.append(name, data[name]);
			}
		}

		try {
			const result = await this.attachmentService.upload(formData);
			this.loading = false;

			if (result?.message?.some(f => f['error'])) {
				this.toastrService.warning(result.message.map(f => f['error']).join(' - '), this.title);
				return;
			} else if (!result || !result.message || result.message.length !== this.files.length || result.message.some(a => !a)) {
				throw new Error();
			}

			this.attachments = result.message.map(a => new Attachment(a));
			this.onChange(!this.attachments.length ? null : this.multiple ? this.attachments : this.attachments[0]);
		} catch (e) {
			this.toastrService.warning(`There was a problem uploading the file${this.multiple ? 's' : ''}. `
				+ 'We have been notified and are working to fix the issue. Please check back again in 30 minutes.', this.title);
			this.loading = false;
		}
	}

	async onClearFile(attachment: Attachment) {
		if (this.disabled || this.loading) { return; }

		this.markAsTouched();

		this.loading = true;

		if (!this.softDelete && attachment.attachmentId) {
			if (this.applyImmediately) {
				await this.deleteAttachment(attachment);
			} else {
				this.deleteQueue.push(attachment);
			}
		}

		this.loading = false;

		this.attachments = this.attachments.filter(a => a !== attachment);
		this.onChange(this.attachments);
	}

	private async deleteAttachment(attachment: Attachment) {
		try {
			const result = await this.graphqlService.deleteAttachment(attachment.attachmentId);
			if (!result) { throw new Error(); }
			if (this.applyImmediately) {
				this.toastrService.success('Successfully removed file!', this.title);
			}
		} catch (e) {
			this.toastrService.warning(`There was a problem deleting the file${this.multiple ? 's' : ''}. `
				+ ' We have been notified and are working to fix the issue. Please check back again in 30 minutes.', this.title);
			this.loading = false;
			return;
		}
	}

	writeValue(attachments: Attachment | Attachment[]) {
		this.attachments = !attachments ? [] : Array.isArray(attachments) ? attachments : [attachments];
	}

	registerOnChange(onChange: (attachment: Attachment | Attachment[]) => void) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}
