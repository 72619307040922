import * as moment from 'moment-timezone';

export class PricingCopilotMonthlyAuctionPerformancesChartPoint {
	public minX: string;
	public dataPoints: {
		date: Date;
		data: number;
		hideTooltip: boolean;
	}[] = [];

	constructor(pricingCopilotMonthlyAuctionPerformancesChartPoint: PricingCopilotMonthlyAuctionPerformancesChartPoint) {
		Object.assign(this, pricingCopilotMonthlyAuctionPerformancesChartPoint);

		if (this.dataPoints) {
			this.dataPoints.forEach(d => new Date(moment(d.date).format('MMM DD, YYYY 00:00:00 a')));
		}
	}
}
