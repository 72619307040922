import { Attachment } from './attachment';

export class AiGeneratedContent {

	public id: number;
	public ticketId: number;
	public content: string;
	public attachmentId: string;

	public attachment: Attachment;

	constructor(aiGeneratedContent: AiGeneratedContent) {
		Object.assign(this, aiGeneratedContent);

		if (aiGeneratedContent?.attachment) {
			this.attachment = new Attachment(aiGeneratedContent.attachment);
		}
	}
}
