export class SmartEnrollOption {

	public id: number;
	public name: string;
	public description: string;
	public minGreenPercentage: number;
	public checkBelowPtc: boolean;

	constructor(smartEnrollOption?: SmartEnrollOption) {
		Object.assign(this, smartEnrollOption);
	}
}
