import { PendingCommission } from './pending-commission';
import { SupplierCommissionMap } from './supplier-commission-map';

export class SupplierCommissionAttachmentSheet {
	public id: number;
	public supplierCommissionAttachmentId: number;
	public supplierCommissionMapId: number;
	public name: string;

	public pendingCommissions: PendingCommission[];
	public supplierCommissionMap: SupplierCommissionMap;

	constructor(sheet: SupplierCommissionAttachmentSheet) {
		Object.assign(this, sheet);

		if (sheet?.pendingCommissions) {
			this.pendingCommissions = sheet.pendingCommissions.map(pc => new PendingCommission(pc));
		}
		if (sheet?.supplierCommissionMap) {
			this.supplierCommissionMap = new SupplierCommissionMap(sheet.supplierCommissionMap);
		}
	}
}
