import * as moment from 'moment-timezone';

export class ViewCommissionReport {

	public id: number;
	public status: string;
	public statusId: number;
	public supplierId: string;
	public parentId: string;
	public supplierName: string;
	public parentSupplierName: string;
	public companyId: string;
	public description: string;
	public attachmentId: string;
	public uploadDate: string | Date;
	public reportDate: string | Date;
	public amount: number;
	public sender: string;
	public flaggedReason: string;
	public isDirectSale: boolean;
	public companySupplierId: number;
	public parentCompanySupplierId: number;
	public showHighReceivableDeviation: boolean;
	public containsNotes: boolean;
	public actualPayableAmount: number;
	public canDelete: boolean;
	public isGeneric: boolean;

	constructor(supplierCommissionAttachment?: ViewCommissionReport) {
		Object.assign(this, supplierCommissionAttachment);
		this.uploadDate = this.uploadDate ? new Date(moment(this.uploadDate).format('MMM DD, YYYY hh:mm:ss a')) : this.uploadDate;
		this.reportDate = this.reportDate ? new Date(moment(this.reportDate).format('MMM DD, YYYY hh:mm:ss a')) : this.reportDate;
	}
}
