import { PricingCopilotMonthlyAuctionPerformancesChartPoint } from './pricing-copilot-monthly-auction-performances-chart-point';

export class PricingCopilotMonthlyAuctionPerformancesChartType {
	public name: string;
	public auctionPerformancesChart: PricingCopilotMonthlyAuctionPerformancesChartPoint;

	constructor(pricingCopilotMonthlyAuctionPerformancesChartType: PricingCopilotMonthlyAuctionPerformancesChartType) {
		Object.assign(this, pricingCopilotMonthlyAuctionPerformancesChartType);

		this.auctionPerformancesChart = this.auctionPerformancesChart
			? new PricingCopilotMonthlyAuctionPerformancesChartPoint(this.auctionPerformancesChart) : this.auctionPerformancesChart;
	}
}
