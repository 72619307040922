import * as moment from 'moment-timezone';

import { environment } from '../../../environments/environment';
import { Attachment } from './attachment';
import { Ticket } from './ticket';
import { User } from './user';

export class TicketComment {

	public id: number;
	public modifiedDate: Date;
	public modifiedUserId: string;
	public ticketId: number;
	public userId: string;
	public body: string;
	public addDate: Date;
	public isInternal: boolean;
	public bcc: string[]; // submission only
	public ocAttachmentId: string;

	public ticket: Ticket;
	public user: User;
	public modifiedUser: User;
	public attachments: Attachment[];

	constructor(ticketComment: TicketComment) {
		Object.assign(this, ticketComment);

		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
		this.ticket = this.ticket ? new Ticket(this.ticket) : this.ticket;
		this.user = this.user ? new User(this.user) : this.user;
		this.modifiedUser = this.modifiedUser ? new User(this.modifiedUser) : this.modifiedUser;
		this.attachments = this.attachments ? this.attachments.map(a => new Attachment(a)) : this.attachments;
		this.body = this.body ? this.body
			.replace(/"\/includes/g, `"${environment.baseUrl}/includes`)
			.replace(/'\/includes/g, `'${environment.baseUrl}/includes`)
			: this.body;
	}
}
