import * as moment from 'moment-timezone';

import { Attachment, SupplierCommissionAttachmentSheet } from './';
import { Company } from './company';
import { NoteType } from './note-type';
import { Supplier } from './supplier';
import { SupplierCommissionAttachmentStatus } from './supplier-commission-attachment-status';
import { User } from './user';

export class SupplierCommissionAttachment {

	public id: number;
	public supplierId: string;
	public companyId: string;
	public attachmentId: string;
	public amount: number;
	public uploadDate: Date;
	public approvalUserId: string;
	public addDate: Date;
	public modifiedDate: Date;
	public noteTypeId: string;
	public statusId: number;
	public reportDate: Date | string;
	public showHighReceivableDeviation: boolean;

	public approvalUser: User;
	public supplier: Supplier;
	public company: Company;
	public attachment: Attachment;
	public status: SupplierCommissionAttachmentStatus;
	public noteType: NoteType;
	public supplierCommissionAttachmentSheets: SupplierCommissionAttachmentSheet[];

	constructor(supplierCommissionAttachment?: SupplierCommissionAttachment) {
		Object.assign(this, supplierCommissionAttachment);

		this.addDate = this.addDate ? new Date(moment(this.addDate).format('MMM DD, YYYY hh:mm:ss a')) : this.addDate;
		this.modifiedDate = this.modifiedDate ? new Date(moment(this.modifiedDate).format('MMM DD, YYYY hh:mm:ss a')) : this.modifiedDate;
		this.uploadDate = this.uploadDate ? new Date(moment(this.uploadDate).format('MMM DD, YYYY hh:mm:ss a')) : this.uploadDate;
		this.reportDate = this.reportDate ? new Date(moment(this.reportDate).format('MMM DD, YYYY hh:mm:ss a')) : this.reportDate;

		this.approvalUser = this.approvalUser ? new User(this.approvalUser) : this.approvalUser;
		this.status = this.status ? new SupplierCommissionAttachmentStatus(this.status) : this.status;
		this.supplier = this.supplier ? new Supplier(this.supplier) : this.supplier;
		this.company = this.company ? new Company(this.company) : this.company;
		this.attachment = this.attachment ? new Attachment(this.attachment) : this.attachment;
		this.noteType = this.noteType ? new NoteType(this.noteType) : this.noteType;

		if (supplierCommissionAttachment?.supplierCommissionAttachmentSheets) {
			this.supplierCommissionAttachmentSheets
				= supplierCommissionAttachment?.supplierCommissionAttachmentSheets.map(s => new SupplierCommissionAttachmentSheet(s));
		}
	}
}
