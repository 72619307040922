

import { PricingCopilotMonthlyAuctionPerformance } from './pricing-copilot-monthly-auction-performances';
import { PricingCopilotMonthlyAuctionPerformancesChartType } from './pricing-copilot-monthly-auction-performances-chart-type';

export class SupplierMonthlyAuctionPerformanceStats {
	monthlyAuctionPerformance: PricingCopilotMonthlyAuctionPerformance;
	monthlyAuctionPerformanceCharts: PricingCopilotMonthlyAuctionPerformancesChartType[];

	constructor(supplierMonthlyAuctionPerformanceStats: SupplierMonthlyAuctionPerformanceStats) {
		Object.assign(this, supplierMonthlyAuctionPerformanceStats);

		this.monthlyAuctionPerformance = this.monthlyAuctionPerformance
			? new PricingCopilotMonthlyAuctionPerformance(this.monthlyAuctionPerformance) : this.monthlyAuctionPerformance;
		this.monthlyAuctionPerformanceCharts = this.monthlyAuctionPerformanceCharts
			? this.monthlyAuctionPerformanceCharts.map(m => new PricingCopilotMonthlyAuctionPerformancesChartType(m))
			: this.monthlyAuctionPerformanceCharts;
	}
}
